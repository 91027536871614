import { SerializedStyles } from "@emotion/react";
import { MotionProps, motion, useCycle } from "framer-motion";
import Inputmask from "inputmask";
import { InputHTMLAttributes, ReactNode, Ref, useCallback, useEffect, useMemo, useRef } from "react";
import * as React from "react";
import AnimatedLabel from "src/components/AnimatedLabel";
import { useInputMask, useLabel, useMergedRef, usePrevious } from "src/hooks";
import { MediaCSSProperties, createStyles } from "src/styles";
import { editInputValueAndForceOnChangeEvent } from "src/utilities/react";

import { colors, formatters, sleep } from "@fraction/shared";

import XButton from "../XButton";

export type TextInputEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export enum Variant {
  STANDARD = "standard",
  DISABLED_OUTLINED = "disabled-outlined",
  V2 = "v2",
  ROUNDED = "rounded",
}

export const ROUNDED_RADIUS = 22;
export const V2_RADIUS = 6;

export interface TextInputProps
  extends Omit<
    React.DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
      HTMLInputElement | HTMLTextAreaElement
    > &
      MotionProps,
    "onChange" | "style" | "onAnimationStart" | "onDragStart" | "onDragEnd"
  > {
  label?: string;
  error?: string;
  disabled?: boolean;
  value?: string;
  placeholder?: string;
  type?: string;
  name?: string;
  onChange?: (val: string, event: TextInputEvent) => void;
  mask?: string | Inputmask.Options["mask"];
  maskOptions?: Inputmask.Options;
  style?: SerializedStyles | Array<SerializedStyles | undefined>;
  inputStyle?: SerializedStyles | SerializedStyles[];
  borderRadius?: number;
  multiline?: boolean;
  divRef?: Ref<HTMLDivElement>;
  adornment?: ReactNode;
  variant?: Variant | string;
  clearable?: boolean;
  errorSpacing?: boolean;
  className?: string;
  containerClassName?: string;
  noCss?: boolean;
  currency?: boolean;
  percentage?: boolean;
  integer?: boolean;
  cents?: boolean;
}

export const INPUT_STYLES: MediaCSSProperties = {
  backgroundColor: "transparent",
  paddingTop: 13,
  paddingBottom: 13,
  paddingLeft: 24,
  paddingRight: 18,
  borderStyle: "solid",
  borderWidth: 1,
  minWidth: 340,
  width: "100%",
  maxWidth: "100%",
  "@media(max-width: 500px)": {
    width: "100%",
    minWidth: "unset",
  },
  boxSizing: "border-box",
  fontFamily: ["Hanken Grotesk", "sans-serif"].join(","),
  letterSpacing: "0.02em",
  color: colors.TEXT,
  margin: 0,
  fontSize: 16,
  fontWeight: 400,
  borderColor: colors.LINES,
  caretColor: colors.SELECTED,
  "&:hover::placeholder": {
    color: colors.HOVER_PLACEHOLDER_TEXT,
  },
  "&::placeholder": {
    color: colors.PLACEHOLDER_TEXT,
  },
  "&:focus": {
    outline: "none",
    borderColor: colors.SELECTED,
  },
  "&:disabled": {
    opacity: 0.8,
    color: colors.DISABLED_TEXT,
  },
};

export const TEXT_INPUT_STYLES = createStyles({
  input: INPUT_STYLES,
  v2: {
    borderRadius: V2_RADIUS,
  },
  rounded: {
    borderRadius: ROUNDED_RADIUS,
  },
  withLabel: {
    // We account for the border with the AnimatedLabel
    borderTopStyle: "none",
  },
  error: {
    borderColor: colors.ERROR,
    "&:focus": {
      borderColor: colors.ERROR,
    },
  },
  errorText: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    letterSpacing: "0.02em",
    margin: 0,
    color: colors.ERROR,
    fontSize: 12,
    marginTop: 3,
    maxWidth: "fit-content",
  },
  spacer: {
    height: 18,
  },
  multiline: {
    height: 120,
    maxHeight: "100%",
    lineHeight: "160%",
    resize: "none",
  },
  container: {
    position: "relative",
    minWidth: "fit-content",
  },
  adornment: {
    position: "absolute",
    top: 0,
    right: 0,
    // the 18px is to account for the spacer. If we want to generalize this, we should move this
    // to a prop
    height: "calc(100% - 18px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "fit-content",
  },
  disabledOutlined: {
    "&:disabled": {
      opacity: 1,
      color: colors.palette.GREY_500,
      backgroundColor: colors.palette.GREY_200,
    },
  },
  clearable: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  clearableSpacing: {
    height: "calc(100% - 18px)",
  },
  clearIcon: {
    backgroundColor: "transparent",
  },
});

const styles = TEXT_INPUT_STYLES;

const TextInput = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, TextInputProps>(
  (
    {
      placeholder,
      label,
      error,
      errorSpacing = true,
      disabled,
      onChange,
      mask,
      maskOptions,
      value,
      defaultValue,
      style,
      inputStyle,
      multiline,
      divRef,
      max,
      min,
      adornment,
      variant = Variant.V2,
      borderRadius = variant === Variant.V2 ? V2_RADIUS : variant === Variant.ROUNDED ? ROUNDED_RADIUS : 10,
      clearable,
      className,
      containerClassName,
      currency,
      percentage,
      integer,
      cents,
      noCss,
      ...props
    }: TextInputProps,
    forwardedRef
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const usesMask = mask || maskOptions || currency || cents || percentage || integer;
    const otherBorderRadii =
      variant === Variant.V2 ? V2_RADIUS : variant === Variant.ROUNDED ? ROUNDED_RADIUS : 0;

    const options = useMemo(
      () => ({
        placeholder: " ",
        clearMaskOnLostFocus: true,
        jitMasking: true,
        autoUnmask: true,
        showMaskOnHover: false,
        max,
        min,
        allowMinus: min === undefined || formatters.number.getNumberFromString(min) < 0,
        ...(currency ? CURRENCY_MASK : {}),
        ...(cents ? CENTS_CURRENCY_MASK : {}),
        ...(percentage ? PERCENTAGE_MASK : {}),
        ...(integer ? INTEGER_MASK : {}),
        ...maskOptions,
      }),
      [max, min, maskOptions, currency, integer, percentage, cents]
    );

    const handleChange = useCallback(
      (event: TextInputEvent) => {
        // @ts-ignore
        onChange?.(event?.target?.value, event);
      },
      [onChange]
    );

    const merged = useMergedRef(
      useInputMask({
        mask,
        options,
        // @ts-ignore
        onChange: handleChange,
      }),
      forwardedRef
    );

    const innerMerged = useMergedRef(merged, innerRef);
    const forwardedMerged = useMergedRef(forwardedRef, innerRef);

    const ref = usesMask ? innerMerged : forwardedMerged;

    const [inputProps, labelProps] = useLabel({
      inputRef: innerRef,
      label,
      placeholder,
      disabled: variant !== Variant.DISABLED_OUTLINED && disabled,
      error,
      defaultValue,
      value,
      ...props,
    });

    const prevError = usePrevious(error);

    const handleClear = useCallback(() => {
      editInputValueAndForceOnChangeEvent("", innerRef);
    }, [innerRef]);

    const [x, cycleX] = useCycle(0, 5, -5, 5, 0);

    useEffect(() => {
      const jiggle = async () => {
        for (let i = 0; i < 5; i++) {
          await sleep(80);
          cycleX();
        }
      };

      if (error && error !== prevError) {
        jiggle();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, prevError]);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Component = multiline ? motion.textarea : motion.input;

    return (
      <div ref={divRef} className={containerClassName} css={noCss ? undefined : [styles.container, style]}>
        <Component
          {...props}
          // @ts-ignore
          onFocus={props.onFocus}
          value={value}
          defaultValue={defaultValue}
          initial={noCss ? undefined : { borderBottomLeftRadius: "0px" }}
          animate={noCss ? undefined : { borderBottomLeftRadius: `${borderRadius}px` }}
          onChange={handleChange}
          className={className}
          disabled={disabled}
          max={max}
          min={min}
          step={props.step}
          {...inputProps}
          css={
            noCss
              ? undefined
              : [
                  styles.input,
                  error && styles.error,
                  multiline && styles.multiline,
                  label && styles.withLabel,
                  { borderColor: labelProps.borderColor },
                  variant === Variant.DISABLED_OUTLINED && styles.disabledOutlined,
                  variant === Variant.V2 && styles.v2,
                  variant === Variant.ROUNDED && styles.rounded,
                  inputStyle,
                ]
          }
          // @ts-ignore
          ref={ref}
        />
        {clearable && (
          <div css={[styles.clearable, errorSpacing ? styles.clearableSpacing : undefined]}>
            <XButton onClick={handleClear} color={colors.palette.GREY_400} style={styles.clearIcon} />
          </div>
        )}
        {adornment && <div css={styles.adornment}>{adornment}</div>}
        {label && (
          <AnimatedLabel {...labelProps} borderRadius={otherBorderRadii}>
            {label}
          </AnimatedLabel>
        )}
        {error && (
          <motion.p animate={{ x }} css={styles.errorText}>
            {error}
          </motion.p>
        )}
        {!error && errorSpacing && <div css={styles.spacer} />}
      </div>
    );
  }
);

export const CURRENCY_MASK = {
  rightAlign: false,
  alias: "currency",
  prefix: "$ ",
  jitMasking: false,
  digits: 0,
};

export const CENTS_CURRENCY_MASK = {
  rightAlign: false,
  alias: "currency",
  prefix: "$ ",
  jitMasking: false,
  digits: 2,
};

export const PERCENTAGE_MASK = {
  alias: "percentage",
  rightAlign: false,
  jitMasking: true,
  suffix: "%",
  digits: 2,
};

export const INTEGER_MASK = {
  alias: "integer",
  digits: 0,
  rightAlign: false,
  jitMasking: true,
};

export default React.memo(
  TextInput,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.maskOptions || {}) === JSON.stringify(nextProps.maskOptions || {}) &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.value === nextProps.value &&
    prevProps.defaultValue === nextProps.defaultValue &&
    prevProps.placeholder === nextProps.placeholder &&
    prevProps.type === nextProps.type &&
    prevProps.step === nextProps.step &&
    prevProps.autoComplete === nextProps.autoComplete &&
    prevProps.name === nextProps.name &&
    prevProps.mask === nextProps.mask &&
    prevProps.onChange === nextProps.onChange &&
    prevProps.error === nextProps.error &&
    prevProps.style === nextProps.style &&
    prevProps.inputStyle === nextProps.inputStyle &&
    prevProps.multiline === nextProps.multiline &&
    prevProps.adornment === nextProps.adornment &&
    prevProps.className === nextProps.className &&
    prevProps.containerClassName === nextProps.containerClassName &&
    prevProps.onSubmit === nextProps.onSubmit &&
    prevProps.noCss === nextProps.noCss &&
    prevProps.currency === nextProps.currency &&
    prevProps.percentage === nextProps.percentage &&
    prevProps.integer === nextProps.integer &&
    prevProps.cents === nextProps.cents
);
