import { useLDClient } from "launchdarkly-react-client-sdk";
import React from "react";
import fraction from "src/api/fraction";
import { setScopeForUser } from "src/api/sentry";
import { useToken } from "src/hooks";
import { useCachedQuery, useCachedQueryClient } from "src/hooks/useCache";
import { useMutation } from "src/lib";
import { generateAlertMessage } from "src/utilities/interface/generateAlert";

import { entities, enums } from "@fraction/shared";

import { STORAGE, loadUser, login, loginFromToken, logout } from "./mutations";

const QUERY_KEY = ["authenticate"];

export const useAuth = ({ refetch: shouldRefetch = true }: { refetch?: boolean } = { refetch: false }) => {
  const queryClient = useCachedQueryClient();
  const ldClient = useLDClient();
  const { isLoading: tokenLoading, token, setToken, id, email } = useToken();

  const {
    data: user,
    error,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useCachedQuery({
    initialRefetch: shouldRefetch,
    refetchInterval: shouldRefetch ? undefined : false,
    refetchOnMount: shouldRefetch,
    refetchOnReconnect: shouldRefetch,
    refetchOnWindowFocus: shouldRefetch,
    enabled: !!id && !tokenLoading,
    placeholderData: () => {
      const token = STORAGE.getToken();
      if (token) {
        fraction.setBearerToken(token);
      }
      const user = STORAGE.getUser();
      if (user) {
        return user;
      }
      return id ? new entities.User({ id, email }) : undefined;
    },
    queryKey: [...QUERY_KEY, id],
    queryFn: async () => {
      if (!token) {
        return null;
      }
      return await loadUser(token);
    },
    onSuccess: (loaded) => {
      if (loaded) {
        STORAGE.setUser(loaded);
      }
    },
  });

  const setUser = React.useCallback(
    (data: entities.User) => {
      if (ldClient?.identify) {
        ldClient.identify({ key: data?.id, ...(data || {}) });
      }
      setScopeForUser(data);
      return queryClient.setQueryData([...QUERY_KEY, data?.id], data);
    },
    [queryClient]
  );

  const updateUser = React.useCallback(
    (data: Partial<entities.User>) =>
      queryClient.setQueryData([...QUERY_KEY, data?.id], { ...user, ...data }),
    [queryClient, user]
  );

  const loginMutation = useMutation({
    mutationFn: login,
    onSuccess: (userData) => {
      setUser(userData?.user);
      setToken(userData?.token);
    },
    meta: {
      errorMessage: (err: any) =>
        generateAlertMessage(err, "There was an error logging in. Please try again."),
    },
  });

  const loginFromTokenMutation = useMutation({
    mutationFn: loginFromToken,
    onSuccess: (userData) => {
      setUser(userData);
    },
    meta: {
      errorMessage: (err: any) =>
        generateAlertMessage(err, "There was an error logging in. Please try again."),
    },
  });

  const resetPasswordMutation = useMutation({
    mutationFn: (password: string) => fraction.resetUserPassword(password),
    onSuccess: (userData) => {
      setUser(userData);
    },
    meta: { errorMessage: "There was an error resetting your password. Please try again." },
  });

  const registerPasswordMutation = useMutation({
    mutationFn: (password: string) => fraction.registerUserPassword(password),
    onSuccess: (userData) => {
      setUser(userData);
    },
    meta: { errorMessage: "There was an error registering. Please try again." },
  });

  const logoutMutation = useMutation({
    mutationFn: logout,
    onSuccess: () => {
      STORAGE.clearUser();
      STORAGE.clearToken();
      queryClient.removeQueries();
    },
    meta: { errorMessage: "There was an error logging out. Please try again." },
  });

  return {
    token,
    user,
    updateUser,
    error: error as Error,
    refetchUser: refetch,
    login: loginMutation.mutateAsync,
    loginFromToken: loginFromTokenMutation.mutateAsync,
    isLoggingIn: loginFromTokenMutation.isPending || loginMutation.isPending,
    logout: logoutMutation.mutateAsync,
    isLoggingOut: logoutMutation.isPending,
    resetPassword: resetPasswordMutation.mutateAsync,
    isResettingPassword: resetPasswordMutation.isPending,
    registerPassword: registerPasswordMutation.mutateAsync,
    isRegisteringPassword: registerPasswordMutation.isPending,
    isLoadingUser: isLoading || tokenLoading || isFetching,
    isLoadingUserError: isError,
    isAuthenticated: user?.accountStatus === enums.UserAccountStatus.REGISTERED,
  };
};
