import { CalendarDays, FolderOpenDot } from "lucide-react";
import { useLoanQuery } from "src/apps/PostFundedDashboard/queries";
import { RouterNavLink } from "src/components";
import { AutoDeposit2, Marketplace } from "src/icons";

export const NavItems = ({ onClick }: { onClick?: () => any }) => {
  const { isLoading, data: loan } = useLoanQuery();
  return (
    <>
      <RouterNavLink onClick={onClick} loading={isLoading} icon={AutoDeposit2} to="/">
        Loan overview
      </RouterNavLink>
      {isLoading || loan?.statements?.length ? (
        <RouterNavLink onClick={onClick} loading={isLoading} icon={CalendarDays} to="/monthly-statements">
          Monthly Statements
        </RouterNavLink>
      ) : null}
      <RouterNavLink onClick={onClick} loading={isLoading} icon={FolderOpenDot} to="/documents">
        Documents
      </RouterNavLink>
      {isLoading || loan?.application?.property?.country === "US" ? (
        <RouterNavLink onClick={onClick} loading={isLoading} icon={Marketplace} to="/marketplace">
          Marketplace
        </RouterNavLink>
      ) : null}
    </>
  );
};
