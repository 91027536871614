import { colors, entities } from "@fraction/shared";
import { Suspense, lazy } from "react";
import ModalBox from "src/components/ModalBox";
import { Loading } from "src/components/Route/Loading";
import XButton from "src/components/XButton";

const Mail = lazy(() => import("src/apps/LOS/pages/MailPage"));

export function MailModal({ close, comm }: { close: () => void; comm?: entities.Communication }) {
  return (
    <ModalBox
      closeButton={
        <XButton onClick={close} color={colors.palette.GREY_800} className="absolute right-3 top-3 z-10" />
      }
      width="60%"
      onClose={close}
      open={!!comm}
    >
      <Suspense fallback={<Loading />}>
        <Mail className="p-6 pr-14 h-[600px] max-h-full overflow-y-scroll" comm={comm} />
      </Suspense>
    </ModalBox>
  );
}
