import { Navigate } from "react-router-dom";
import { BrokerDashboardRoutes } from "src/apps/BrokerDashboard/routes";
import { ConveyancerDashboardRoutes } from "src/apps/ConveyancerDashboard/routes";
import { LOSRoutes } from "src/apps/LOS/routes";
import { PostFundedDashboard } from "src/apps/PostFundedDashboard/screens";
import { PreFundedDashboard } from "src/apps/PreFundedDashboard/routes";
import { Loading } from "src/components";
import { useAccountViewPreference } from "src/hooks/useAccountViewPreference";

export const BrokerOrBorrowerRoutes = () => {
  const { preference, isLoading } = useAccountViewPreference();

  if (isLoading && !preference) {
    return <Loading />;
  }

  if (preference === "broker") {
    return <BrokerDashboardRoutes />;
  } else if (preference === "applicant") {
    return <PreFundedDashboard />;
  } else if (preference === "borrower") {
    return <PostFundedDashboard />;
  } else if (preference === "employee") {
    return <LOSRoutes />;
  } else if (preference === "servicing") {
    return <Navigate to="/portfolio/analytics" />;
  } else if (preference === "conveyancer") {
    return <ConveyancerDashboardRoutes />;
  }

  return <Loading />;
};
