import { entities, enums, formatters, isStatusBeforeStatus, selectors } from "@fraction/shared";
import { isSameDay, parseISO } from "date-fns";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { AppStatusBadge } from "src/components/AppStatusBadge";
import { KeyValue } from "src/components/KeyValue";
import { ModifiableKeyValue } from "src/components/ModifiableKeyValue";
import { ModifiableTextArea } from "src/components/ModifiableTextArea";
import { useAccountViewPreference } from "src/hooks/useAccountViewPreference";
import { cn } from "src/utilities/shadcnUtils";

export const ApplicationOverview = ({
  loading,
  app,
  className,
  header,
}: { loading?: boolean; app?: entities.ApplicationT; className?: string; header?: string }) => {
  const { preference } = useAccountViewPreference();

  const handleCloseDateChange = useCallback((dateStr: string) => {
    const date = parseISO(dateStr);

    const { date: closestPreviousTuesdayOrThursday, day } =
      formatters.date.findClosestPreviousTuesdayOrThursday(date);

    if (date && closestPreviousTuesdayOrThursday && !isSameDay(date, closestPreviousTuesdayOrThursday)) {
      toast.info(
        `Fraction only funds on Tuesdays and Thursdays. Setting the requested close date to the previous ${day}.`
      );
    }

    return formatters.date.iso8601(closestPreviousTuesdayOrThursday);
  }, []);

  const canModifyEstimatedCloseDate =
    preference === "employee" ||
    (preference === "conveyancer" &&
      app?.status &&
      isStatusBeforeStatus(app?.status, enums.ApplicationStatus.READY_FOR_CAPITAL_CALL, true));

  return (
    <div
      className={cn(
        "border p-6 border-gray-400 rounded max-w-full md:max-w-3xl overflow-y-scroll max-h-[700px] overflow-x-scroll",
        className
      )}
    >
      <div>
        <div className="flex flex-row flex-wrap gap-2 mb-3 justify-between items-center">
          <p className="text-2xl font-bold">{header ? header : "Application overview"}</p>
          <AppStatusBadge className="float-right" loading={loading} status={app?.status} />
        </div>
        <p className="text-md mb-3">
          This is an overview of your loan application details. If anything looks incorrect, please let us
          know.
        </p>
      </div>
      <div className="flex flex-col [&>*:nth-child(odd)]:bg-gray-200 [&>*:nth-child(even)]:white hover:[&>*]:bg-gray-300">
        <KeyValue label="Application ID" loading={loading} value={app?.eid} />
        {app?.declineReason ? (
          <KeyValue label="Decline reason(s)" loading={loading} value={app.declineReason} />
        ) : null}
        <KeyValue
          label="Property address"
          loading={loading}
          value={formatters.property.formattedAddress(app?.property)}
        />
        {app && selectors.application.selectLatestAppraisal(app)?.value ? (
          <KeyValue
            label="Appraised value"
            loading={loading}
            value={formatters.number.getCurrencyFromNumber(
              selectors.application.selectLatestAppraisal(app)?.value
            )}
          />
        ) : null}
        {app?.confirmedLoanAmount ? (
          <KeyValue
            label="Confirmed loan amount"
            loading={loading}
            value={formatters.number.getCurrencyFromNumber(app.confirmedLoanAmount)}
          />
        ) : app?.approvedAmount ? (
          <KeyValue
            label="Approved loan amount"
            loading={loading}
            value={formatters.number.getCurrencyFromNumber(app.approvedAmount)}
          />
        ) : null}
        <KeyValue
          label="Total loan amount w/ origination fee"
          loading={loading}
          value={formatters.number.getCurrencyFromNumber(
            selectors.application.selectApplicationTotalAmount(app)
          )}
        />
        <KeyValue
          label="Origination fee"
          loading={loading}
          value={
            app?.originationFeeInCents
              ? formatters.number.getCurrencyFromNumber(app?.originationFeeInCents)
              : "Not set"
          }
        />
        {preference !== "conveyancer" ? (
          <ModifiableKeyValue
            appId={app?.id}
            label="Requested close date"
            loading={loading}
            path="requestedCloseDate"
            type="date"
            formatValue={formatters.date.iso8601}
            mutateValueBeforeUpdate={handleCloseDateChange}
          />
        ) : null}
        {preference !== "conveyancer" ? (
          <ModifiableKeyValue
            appId={app?.id}
            label="Requested loan amount"
            loading={loading}
            path="requestedAmount"
            cents
          />
        ) : null}

        {app?.estimatedCloseDate && canModifyEstimatedCloseDate ? (
          <ModifiableKeyValue
            appId={app?.id}
            label="Estimated close date"
            loading={loading}
            path="estimatedCloseDate"
            type="date"
            formatValue={formatters.date.iso8601}
            mutateValueBeforeUpdate={handleCloseDateChange}
          />
        ) : null}
        {["employee", "conveyancer"].includes(preference) ? (
          <ModifiableKeyValue
            appId={app?.id}
            label="Signing appointment date"
            loading={loading}
            path="targetILRAppointmentDate"
            type="date"
            formatValue={formatters.date.iso8601}
          />
        ) : app?.targetILRAppointmentDate ? (
          <KeyValue
            label="Signing appointment date"
            loading={loading}
            value={formatters.date.formatDate(app.targetILRAppointmentDate)}
          />
        ) : null}
        {app?.estimatedCloseDate && !canModifyEstimatedCloseDate ? (
          <KeyValue
            label="Estimated close date"
            loading={loading}
            value={formatters.date.formatDate(app.estimatedCloseDate)}
          />
        ) : null}
        {app?.advanceDate && ["employee"].includes(preference) ? (
          <ModifiableKeyValue
            appId={app?.id}
            label="Advance date"
            loading={loading}
            path="advanceDate"
            type="date"
            formatValue={formatters.date.iso8601}
          />
        ) : null}
        {app?.advanceDate && !["employee"].includes(preference) ? (
          <KeyValue
            label="Advance date"
            loading={loading}
            value={formatters.date.formatDate(app.advanceDate)}
          />
        ) : null}

        {app?.closeDate ? (
          <KeyValue
            label="Official close date"
            loading={loading}
            value={formatters.date.formatDate(app.closeDate)}
          />
        ) : null}
        {app?.applicants?.map((applicant, index) => (
          <KeyValue
            key={applicant.id}
            label={`Applicant #${index + 1}`}
            loading={loading}
            value={`${formatters.user.userName(applicant.user)} ${
              applicant.user?.email ? `(${applicant.user.email})` : ""
            }`}
          />
        ))}
        {preference === "employee" ? (
          <KeyValue label="Conveyancer" loading={loading} value={app?.conveyancerGroup?.name} />
        ) : null}
        {["conveyancer", "employee"].includes(preference) ? (
          <ModifiableTextArea
            label="Conveyancer notes"
            loading={loading}
            appId={app?.id}
            path="conveyancerNotes"
            value={app?.conveyancerNotes}
          />
        ) : null}
      </div>
    </div>
  );
};
