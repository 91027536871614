import { SerializedStyles } from "@emotion/react";
import { ReactNode, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import ThreeDotsWave from "src/components/ThreeDotsWave";
import Touchable from "src/components/Touchable";

import { usePrevious } from "src/hooks";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export type ButtonType =
  | "primary"
  | "secondary"
  | "urgent"
  | "inverse"
  | "inversePrimary"
  | "ghost"
  | "ghostUrgent";

export interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children?: ReactNode;
  style?: SerializedStyles;
  textCss?: SerializedStyles;
  align?: "left" | "right" | "center";
  type?: ButtonType;
  size?: "standard" | "small";
  loading?: boolean;
  ready?: boolean;
  narrow?: boolean;
  disabled?: boolean;
  submit?: boolean;
  postLoadingTextOverride?: string;
}

const FOCUS_OUTLINE_OFFSET = 6;
const FOCUS_OUTLINE_OFFSET_GHOST = 18;

const styles = createStyles({
  base: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:focus-visible": {
      outline: "none", // Disable default focus style
    },
  },
  baseContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ghostBaseContainer: {
    position: "relative",
  },
  focusOutline: {
    display: "none",
    position: "absolute",
    bottom: -FOCUS_OUTLINE_OFFSET,
    top: -FOCUS_OUTLINE_OFFSET,
    left: -FOCUS_OUTLINE_OFFSET,
    right: -FOCUS_OUTLINE_OFFSET,
    borderStyle: "solid",
    borderColor: colors.BUTTON_FOCUS_OUTLINE,
    borderWidth: 2,
    filter: "blur(1px)",
    pointerEvents: "none",
    ["button:focus-visible &" as any]: {
      display: "block",
    },
  },
  standardFocusOutline: {
    borderRadius: 100,
  },
  smallFocusOutline: {
    borderRadius: 14,
  },
  ghostFocusOutline: {
    bottom: -FOCUS_OUTLINE_OFFSET_GHOST,
    top: -FOCUS_OUTLINE_OFFSET_GHOST,
    left: -FOCUS_OUTLINE_OFFSET_GHOST,
    right: -FOCUS_OUTLINE_OFFSET_GHOST,
  },
  primary: {
    backgroundColor: colors.button.PRIMARY.BACKGROUND,
    "&:hover": {
      backgroundColor: colors.palette.GREEN_300,
    },
  },
  secondary: {
    backgroundColor: colors.button.SECONDARY.BACKGROUND,
    "&:hover": {
      backgroundColor: colors.palette.YELLOW_300,
    },
  },
  urgent: {
    backgroundColor: colors.button.URGENT.BACKGROUND,
    "&:hover": {
      backgroundColor: colors.palette.RED_300,
    },
  },
  inverse: {
    backgroundColor: colors.button.INVERSE.BACKGROUND,
    borderColor: colors.button.INVERSE.OUTLINE,
    borderStyle: "solid",
    borderWidth: 1,
    "&:hover": {
      backgroundColor: colors.palette.GREY_100,
    },
  },
  inversePrimary: {
    borderColor: colors.button.INVERSE_PRIMARY.OUTLINE,
    borderStyle: "solid",
    borderWidth: 1,
  },
  ghost: {
    backgroundColor: colors.button.GHOST.BACKGROUND,
    "&:hover": {
      backgroundColor: colors.palette.GREY_100,
    },
  },
  ghostUrgent: {
    backgroundColor: colors.button.GHOST_URGENT.BACKGROUND,
    "&:hover": {
      backgroundColor: colors.palette.GREY_100,
    },
  },
  primaryText: {
    color: colors.button.PRIMARY.TEXT,
  },
  secondaryText: {
    color: colors.button.SECONDARY.TEXT,
  },
  urgentText: {
    color: colors.button.URGENT.TEXT,
  },
  inverseText: {
    color: colors.button.INVERSE.TEXT,
  },
  inversePrimaryText: {
    color: colors.button.INVERSE_PRIMARY.TEXT,
  },
  ghostText: {
    color: colors.button.GHOST.TEXT,
    fontWeight: 400,
    fontSize: 16,
  },
  ghostUrgentText: {
    color: colors.button.GHOST_URGENT.TEXT,
    fontWeight: 600,
    fontSize: 16,
  },
  standardButtonSize: {
    borderRadius: 24,
    minWidth: 120,
    minHeight: 44,
  },
  smallButtonSize: {
    borderRadius: 10,
    minWidth: 80,
    minHeight: 27,
  },
  narrow: {
    width: "fit-content",
    "@media(max-width: 420px)": {
      width: "100%",
    },
  },
  standardButton: {
    padding: "12px 23px 12px 23px ",
  },
  smallButton: {
    padding: "4px 10px 4px 10px",
  },
  baseText: {
    fontFamily: "Montserrat",
    margin: 0,
  },
  leftText: {
    textAlign: "left",
  },
  rightText: {
    textAlign: "right",
  },
  centerText: {
    textAlign: "center",
  },
  leftButton: {
    paddingLeft: 0,
    justifyContent: "flex-start",
  },
  rightButton: {
    paddingRight: 0,
    justifyContent: "flex-end",
  },
  centerButton: {
    justifyContent: "center",
  },
  standardText: {
    fontSize: 14,
    fontWeight: 600,
  },
  smallText: {
    fontSize: 12,
    fontWeight: 700,
  },
  doneButton: {
    backgroundColor: colors.button.SECONDARY.BACKGROUND,
  },
  primaryLoading: {
    backgroundColor: colors.button.PRIMARY.LOADING,
  },
  secondaryLoading: {
    backgroundColor: colors.button.SECONDARY.LOADING,
  },
  urgentLoading: {
    backgroundColor: colors.button.URGENT.LOADING,
  },
  inverseLoading: {
    backgroundColor: colors.button.INVERSE.LOADING,
  },
  ghostLoading: {
    backgroundColor: colors.button.GHOST.LOADING,
  },
  disabled: {
    backgroundColor: colors.DISABLED_BUTTON,
    opacity: 0.8,
    border: "none",
  },
  disabledText: {
    opacity: 0.8,
    color: "white",
  },
});

const Button = ({
  onClick,
  children,
  style,
  textCss,
  align = "center",
  type = "primary",
  size = "standard",
  loading = false,
  ready = true,
  narrow = false,
  disabled = false,
  submit = false,
  postLoadingTextOverride,
}: ButtonProps) => {
  const [textOverride, setTextOverride] = useState<undefined | string>();
  const prevLoading = usePrevious(loading);

  useEffect(() => {
    if (prevLoading && !loading && postLoadingTextOverride) {
      setTextOverride(postLoadingTextOverride);
      setTimeout(() => {
        setTextOverride(undefined);
      }, 2000);
    }
  }, [loading, prevLoading, postLoadingTextOverride]);

  const buttonStyle = [
    styles[`${size}Button` as `${typeof size}Button`],
    styles[`${size}ButtonSize` as `${typeof size}ButtonSize`],
    styles[type],
    styles.base,
    narrow && styles.narrow,
    disabled && styles.disabled,
    styles[`${align}Button` as `${typeof align}Button`],
    textOverride !== undefined && styles.doneButton,
    style,
  ];
  const focusOutlineStyle = [
    styles.focusOutline,
    styles[`${size}FocusOutline` as `${typeof size}FocusOutline`],
    // @ts-ignore
    styles[`${type}FocusOutline` as `${typeof type}FocusOutline`],
  ];
  const textStyle = [
    styles.baseText,
    styles[`${size}Text` as `${typeof size}Text`],
    styles[`${type}Text` as `${typeof type}Text`],
    disabled && styles.disabledText,
    styles[`${align}Text` as `${typeof align}Text`],
    textCss,
  ];

  const getEstimatedButtonWidth = () => {
    if (typeof children !== "string" && !Array.isArray(children)) {
      return 0;
    }
    return Math.max(children?.length * 9.75, 100);
  };

  if (!ready) {
    return (
      <Skeleton
        css={styles[`${size}ButtonSize` as `${typeof size}ButtonSize`]}
        width={getEstimatedButtonWidth()}
      />
    );
  }

  let buttonType: "submit" | "reset" | "button" = "button";
  if (submit) {
    buttonType = "submit";
  }

  const mappedChildren = Array.isArray(children) ? (
    children.map((child) =>
      typeof child === "string" ? (
        <p className="text-base" css={textStyle}>
          {textOverride !== undefined ? textOverride : child}
        </p>
      ) : (
        child
      )
    )
  ) : typeof children === "string" ? (
    <p className="text-base" css={textStyle}>
      {textOverride !== undefined ? textOverride : children}
    </p>
  ) : (
    children
  );

  return (
    <Touchable type={buttonType} disabled={disabled} onClick={onClick} css={buttonStyle}>
      <div
        css={[
          styles.baseContainer,
          // @ts-ignore
          styles[`${type}BaseContainer` as `${typeof type}BaseContainer`],
        ]}
      >
        {!loading && mappedChildren}
        {loading && (
          <ThreeDotsWave
            dotCss={
              // @ts-ignore
              styles[`${type}Loading` as `${typeof type}Loading`]
            }
          />
        )}
        <div css={focusOutlineStyle} />
      </div>
    </Touchable>
  );
};

export default Button;
