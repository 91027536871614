import { colors } from "@fraction/shared";
import retry from "@fullstax/p-retry";
import { Suspense, lazy } from "react";
import ModalBox from "src/components/ModalBox";
import { Loading } from "src/components/Route/Loading";
import XButton from "src/components/XButton";

const Classifier = lazy(() => retry(() => import("src/embeds/Classifier/Classifier")));

export function ClassifierModal({
  close,
  open,
  onSuccessfulUpload,
  appId,
}: { open?: boolean; close: () => void; onSuccessfulUpload: () => void; appId?: string }) {
  return (
    <ModalBox
      closeButton={
        <XButton
          onClick={close}
          color={colors.palette.GREY_800}
          className="absolute right-0 top-[-3px] z-10"
        />
      }
      closeOnClickBackdrop={false}
      closeOnEscape={false}
      width="100%"
      onClose={close}
      open={open}
    >
      <Suspense fallback={<Loading />}>
        <Classifier
          onSuccessfulUpload={onSuccessfulUpload}
          className="w-full h-[100svh] rounded-md"
          applicationId={appId}
          enabled={!!appId}
        />
      </Suspense>
    </ModalBox>
  );
}
