import { debounce } from "@fraction/shared";
import { DocumentProps, usePDF } from "@react-pdf/renderer";
import { JSXElementConstructor, ReactElement, forwardRef, useEffect, useImperativeHandle } from "react";
import Skeleton from "src/components/Skeleton";
import { Download } from "src/icons";
import { useMutation } from "src/lib";
import { downloadFile } from "src/utilities/file/download";
import { cn } from "src/utilities/shadcnUtils";
import ScrollablePDFViewer from "./ScrollablePDFViewer";

/**
 * A PDF viewer that can be used to display PDFs in the browser, including on mobile. Memoize the children
 * to prevent unnecessary re-renders.
 */
export const PDFViewer = forwardRef(
  (
    {
      children,
      filename,
      className,
      download,
      onDownload,
    }: {
      children: ReactElement<DocumentProps, string | JSXElementConstructor<any>>;
      filename?: string;
      className?: string;
      download?: boolean | string;
      onDownload?: () => void;
    },
    ref
  ) => {
    const [instance, updateInstance] = usePDF({ document: children });

    const debouncedUpdateInstance = debounce(updateInstance, 100);

    useEffect(() => {
      debouncedUpdateInstance(children);
    }, [children]);

    const downloadMutation = useMutation({
      mutationFn: async (event: any) => {
        if (!instance.url) {
          return null;
        }
        onDownload?.();
        return downloadFile(instance.url, `${filename}.pdf`);
      },
    });

    useImperativeHandle(ref, () => ({
      download: downloadMutation.mutateAsync,
    }));

    if (instance.loading) {
      return <Skeleton width="100%" height="100%" />;
    }

    if (instance.error) {
      return <div>Something went wrong: {instance.error}</div>;
    }

    return (
      <div className={className}>
        {download ? (
          <div className="h-12 bg-gray w-full rounded-t items-center flex px-6 justify-between">
            <div />
            <button
              onClick={downloadMutation.mutate}
              className="flex flex-row items-center gap-3 text-white text-sm"
            >
              {typeof download === "string" ? download : null}
              <Download className="text-white" />
            </button>
          </div>
        ) : null}
        <ScrollablePDFViewer className={cn("h-full w-full")} newTab={false} file={instance.url} />
      </div>
    );
  }
);
