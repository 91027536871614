import { enums, formatters } from "@fraction/shared";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { ApplicationOverview } from "src/apps/PreFundedDashboard/components/ApplicationOverview";
import { useChecklist } from "src/apps/PreFundedDashboard/hooks/checklist";
import { AppChat } from "src/components/AppChat";
import { getCurrentStatusBlock } from "src/components/AppStatusBadge";
import { BackButton } from "src/components/BackButton";
import { ClassifierModal } from "src/components/ClassifierModal";
import { DocumentChecklist } from "src/components/DocumentChecklist";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { useApplicationAuthed } from "src/hooks/useApplication";
import { useCachedState } from "src/hooks/useCache";

export function AppDetails() {
  const { id } = useParams();
  const [showComplexUpload, setShowComplexUpload] = useCachedState(false, `complex-upload-toggle-${id}`);
  const { data: app, isFetching, isLoading } = useApplicationAuthed({ id });

  const { refetch: refetchChecklist } = useChecklist({
    id: id,
    status: enums.ApplicationStatus.FUNDED,
  });

  const toggleComplexUpload = useCallback(() => {
    setShowComplexUpload((prev) => !prev);
  }, []);

  const closeComplexUpload = useCallback(() => {
    setShowComplexUpload(false);
  }, []);

  const title = app?.id
    ? `${formatters.application.applicantNames(app)} | ${
        app?.status ? getCurrentStatusBlock(app?.status)?.label : "Fraction"
      }`
    : "App details";

  useDocumentTitle(title);

  if (!isLoading && !app) {
    return <p>App not found</p>;
  }

  return (
    <>
      <div className="p-4 flex flex-col gap-4">
        <BackButton loading={isFetching} label={title} />
        <div className="gap-2 flex flex-row flex-wrap lg:flex-nowrap w-full">
          <ApplicationOverview
            className="min-w-[200px] lg:max-w-3xl  max-w-full w-full"
            loading={isLoading}
            app={app || undefined}
          />
          <DocumentChecklist
            status={enums.ApplicationStatus.FUNDED}
            className="lg:max-w-3xl max-w-full w-full"
            onClickComplexUpload={toggleComplexUpload}
            applicationId={app?.id}
          />
        </div>

        <ClassifierModal
          close={closeComplexUpload}
          open={showComplexUpload}
          onSuccessfulUpload={refetchChecklist}
          appId={app?.id}
        />
      </div>
      <AppChat
        textBoxClassName="h-[400px]"
        className="shadow fixed bottom-0 right-20"
        applicationId={app?.id}
      />
    </>
  );
}
