import { enums, formatters, isStatusAfterStatus, selectors } from "@fraction/shared";
import { STATUS_ORDER, getCurrentStatusBlock } from "src/components/AppStatusBadge";
import Skeleton from "src/components/Skeleton";
import Tooltip from "src/components/Tooltip";
import { cn } from "src/utilities/shadcnUtils";

export const FundingProgress = ({
  status,
  className,
  loading,
  rejectReason,
}: { status?: enums.ApplicationStatus; className?: string; loading?: boolean; rejectReason?: string }) => {
  const currentBlock = status ? getCurrentStatusBlock(status) : undefined;
  const nextBlock =
    currentBlock && status && selectors.isActiveDeal({ status })
      ? Object.entries(STATUS_ORDER)?.[currentBlock?.idx + 1]
      : undefined;

  const isRejected = status === enums.ApplicationStatus.REJECTED;

  return (
    <div className={cn("border p-6 border-gray-400 rounded max-w-3xl", className)}>
      <p className="text-2xl mb-1 font-bold">Funding progress</p>
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-row justify-between items-center">
          {status ? (
            <p>
              Stage:{" "}
              <b>{getCurrentStatusBlock(status)?.label || formatters.enums.APPLICATION_STATUS_MAP[status]}</b>
            </p>
          ) : loading ? (
            <Skeleton className="w-32 h-5" />
          ) : null}
          {nextBlock ? (
            <p className="text-gray-600">
              Next: <b>{nextBlock?.[0]}</b>
            </p>
          ) : loading ? (
            <Skeleton className="w-32 h-5" />
          ) : null}
        </div>
        {!isRejected ? (
          <div className="flex flex-row items-center gap-x-1">
            {Object.entries(STATUS_ORDER)
              ?.filter(
                ([label]) =>
                  !["Pending decline", "Rescinded", "Rejected"].includes(label) &&
                  (status && isStatusAfterStatus(status, enums.ApplicationStatus.MISSING_INFORMATION)
                    ? label !== "Requires more information"
                    : true)
              )
              ?.map(([label, block], idx) => {
                const isCurrent = block?.find((item) => item === status);
                return (
                  <Tooltip key={label} text={label}>
                    <div
                      className={cn(
                        "w-full rounded p-1 h-8",
                        isCurrent ? "bg-green-200 hover:bg-green-400" : "bg-gray-200 hover:bg-gray-400"
                      )}
                      key={label}
                    />
                  </Tooltip>
                );
              })}
          </div>
        ) : (
          <div className="flex flex-col gap-1">
            <p>
              This file was declined by Fraction's underwriting team.{" "}
              {rejectReason ? "The reason provided was:" : ""}
            </p>
            {rejectReason ? (
              <p className="text-gray-600 mt-1 bg-gray-100 py-4 px-3 rounded border-l-4 border-l-gray-400">
                {rejectReason}
              </p>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};
