import { Global, SerializedStyles, css } from "@emotion/react";
import { ReactNode, memo, useCallback, useState } from "react";
import { Document, Page } from "react-pdf";
import Chip from "src/components/Chip";
import Skeleton from "src/components/Skeleton";
import Touchable from "src/components/Touchable";
import { Button } from "src/components/v1";
import { useDimensions } from "src/hooks";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

import useKeyboardListener from "../../hooks/useKeyboardListener";

export interface ScrollablePDFViewerProps {
  // it's type any because that's what react-pdf expects
  file: any;
  style?: SerializedStyles;
  newTab?: boolean;
  zoom?: boolean;
  children?: ReactNode;
  className?: string;
}

const NO_MARGIN = css`
  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const GlobalStyles = () => <Global styles={NO_MARGIN} />;

const styles = createStyles({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  docWrapper: {
    height: "100%",
    width: "100%",
    backgroundColor: "white",
    border: "solid 1px",
    borderColor: colors.BORDER,
    overflow: "overlay",
    overflowX: "scroll",
    "::-webkit-scrollbar": {
      WebkitAppearance: "none",
      width: "7px",
      height: "7px",
    },
    "::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      backgroundColor: "rgba(0, 0, 0, .5)",
      boxShadow: "0 0 1px rgba(255, 255, 255, .5)",
    },
  },
  zoomDocWrapper: {
    "&:hover": {
      cursor: "zoom-in",
    },
  },
  zoomOutDocWrapper: {
    "&:hover": {
      cursor: "zoom-out",
    },
  },
  button: {
    marginTop: 4,
    color: colors.palette.GREEN,
  },
  zoomResetButton: {
    position: "absolute",
    top: 10,
    right: 10,
  },
});

const ScrollablePDFViewer = ({
  file,
  style,
  newTab = true,
  zoom,
  children,
  className,
}: ScrollablePDFViewerProps) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [scale, setScale] = useState(1);

  const onDocumentLoadSuccess = useCallback(({ numPages: nextNumPages }: { numPages: number }) => {
    setNumPages(nextNumPages);
  }, []);

  const handleResetZoom = useCallback(() => {
    setScale(1);
  }, []);

  const { width, height: containerHeight, observe } = useDimensions<HTMLDivElement>();

  const loading = <Skeleton className="w-full" css={{ height: containerHeight, width }} />;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!zoom) {
        return;
      }
      if (event.metaKey || event.ctrlKey) {
        // zoom out
        setScale((prev) => prev - 0.2);
      } else {
        // zoom in
        setScale((prev) => prev + 0.2);
      }
    },
    [zoom]
  );

  const holdingDownCmd = useKeyboardListener(["Meta", "Control"]);

  return (
    <div className={className} css={[styles.container, style]}>
      <GlobalStyles />
      <div
        onClick={handleClick}
        css={[
          styles.docWrapper,
          zoom && styles.zoomDocWrapper,
          zoom && holdingDownCmd && styles.zoomOutDocWrapper,
        ]}
        ref={observe}
      >
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess} loading={loading}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              loading={loading}
              width={width * scale}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          ))}
        </Document>
      </div>
      {newTab && (
        <a href={file?.url} target="_blank" rel="noopener noreferrer" css={{ textDecoration: "none" }}>
          <Button type="ghost" textCss={styles.button} narrow align="right">
            View in new tab &#8594;
          </Button>
        </a>
      )}
      {zoom && scale !== 1 && (
        <Touchable css={styles.zoomResetButton} onClick={handleResetZoom}>
          <Chip>Reset zoom</Chip>
        </Touchable>
      )}
      {children}
    </div>
  );
};

export default memo(ScrollablePDFViewer);
