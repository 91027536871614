import { formatters } from "@fraction/shared";
import { formatRelative } from "date-fns";
import { ChevronDown, ChevronUp, Loader, MessageCircle } from "lucide-react";
import { ChangeEvent, useCallback, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useAuth } from "src/auth";
import Avatar from "src/components/Avatar";
import { AlwaysScrollToLastChild } from "src/components/ScrollOnMount";
import { useCachedState } from "src/hooks/useCache";
import { useChat } from "src/hooks/useChat";
import { cn } from "src/utilities/shadcnUtils";

export function AppChat({
  applicationId,
  className,
  textBoxClassName,
}: { applicationId?: string; className?: string; textBoxClassName?: string }) {
  const { user } = useAuth();
  const { chats, sendMessage, isPending } = useChat({ applicationId });
  const [text, setText] = useState("");
  const [expanded, setExpanded] = useCachedState(true, "app-chat-expanded");

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  }, []);

  const handlePressSend = useCallback(
    (e?: any) => {
      e?.preventDefault?.();

      if (!applicationId) {
        return;
      }
      return sendMessage({ message: text, applicationId });
    },
    [text, applicationId, sendMessage]
  );

  const handleToggleExpanded = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  if (!applicationId) {
    return null;
  }

  if (!expanded) {
    return (
      <button
        onClick={handleToggleExpanded}
        className={cn(
          "bg-green w-[355px] h-[40px] rounded-t items-center flex justify-between text-white text-sm gap-1 px-4 text-center",
          className
        )}
      >
        <ChevronUp height={20} />
        Expand chat
        <MessageCircle height={20} />
      </button>
    );
  }

  return (
    <div className={cn("bg-white rounded w-[355px]", className)}>
      <button
        onClick={handleToggleExpanded}
        className={
          "bg-green w-[355px] h-[40px] rounded-t items-center flex justify-between text-white text-sm gap-1 px-4 text-center"
        }
      >
        <ChevronDown height={20} />
        Hide chat
        <MessageCircle height={20} />
      </button>
      <AlwaysScrollToLastChild className={cn("p-4 overflow-y-scroll", textBoxClassName)}>
        {chats?.map((chat, chatGroupIdx) => {
          const isLastChatGroup = chatGroupIdx === (chats?.length || 1) - 1;
          const isThisUser = chat.user?.id === user?.id;
          return (
            <div
              className="flex gap-2 flex-row pl-8 pr-4 relative"
              key={`${chat.user?.id}-${chat.chats?.[(chat.chats?.length || 1) - 1]?.id}`}
            >
              {!isThisUser ? (
                <Avatar
                  className="align-end absolute left-[-4px] top-9 w-[26px] h-[26px] text-xs"
                  title={formatters.user.userName(chat.user)}
                >
                  {chat.user?.firstName?.[0]}
                  {chat.user?.lastName?.[0]}
                </Avatar>
              ) : null}
              <div className={cn("flex flex-col gap-3 w-full", isThisUser ? "items-end" : "items-start")}>
                {chat?.chats?.map((message, index) => {
                  const isLastChat = index === (chat?.chats?.length || 1) - 1 && isLastChatGroup;
                  return (
                    <div
                      className={cn("w-full flex flex-col", isThisUser ? "items-end" : "items-start")}
                      key={message.id}
                    >
                      <div className="w-full flex items-center justify-center">
                        {message.createdAt ? (
                          <p className="text-xs text-gray text-center align-middle">
                            {formatters.string.capitalize(formatRelative(message.createdAt, new Date()))}
                          </p>
                        ) : null}
                      </div>
                      <div className="flex flex-col max-w-[60%] w-fit">
                        <p
                          className={cn(
                            "text-[8px] text-gray",
                            isThisUser ? "text-right translate-x-[-16px]" : "text-left translate-x-[16px]"
                          )}
                        >
                          {message.user?.firstName}
                        </p>
                        <div className="flex flex-row gap-1 items-center">
                          <div
                            className={cn(
                              "flex flex-col text-start p-2 px-3 rounded-full text-xs",
                              isThisUser ? "bg-green-400" : "bg-gray-400"
                            )}
                          >
                            {message.message?.split("\n").map((x) => (
                              <p key={x}>{x}</p>
                            ))}
                          </div>
                          {isLastChat && isPending && (
                            <Loader height={16} className="text-gray-600 animate-spin" />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </AlwaysScrollToLastChild>
      <form onSubmit={handlePressSend} className="w-full flex flex-row gap-2 px-4 p-2 border">
        <TextareaAutosize
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handlePressSend();
              setText("");
            }
          }}
          onChange={handleChange}
          value={text}
          className="rounded-full outline-green focus:outline outline-offset-0 text-sm text-left text-ellipsis line-clamp-2 bg-gray-400 py-2 px-5 w-full min-w-auto"
        />
        <button
          type="submit"
          className="bg-green rounded-full text-sm text-white w-20 flex items-center justify-center"
          onClick={handlePressSend}
        >
          Send
        </button>
      </form>
    </div>
  );
}
